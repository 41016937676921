import React from "react";
import { NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import {
  faHome,
  faPhoneAlt,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { PakMap, TopBanner } from "../../Component";
import "./contact-us.css";

function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <>
      <TopBanner PageName="Contact Us"></TopBanner>
      <main className="main-sec">
        <div className="contact-sec-wrap margin-bottom-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="company-info">
                  <FontAwesomeIcon icon={faHome} />
                  <h3>Address</h3>
                  <address>Deewan Welfare Society Jehlum</address>
                </div>
              </div>
              <div className="col">
                <div className="company-info">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  <h3>Phone</h3>
                  <NavLink href="tel:923455708423">+92-345-5708423</NavLink>
                  <NavLink href="mailto:inquire@sagri.org" className="email">
                    inquire@sagri.org
                  </NavLink>
                </div>
              </div>
              <div className="col">
                <div className="company-info">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <h3>Email</h3>
                  <NavLink href="mailto:support@sagri.org" className="email">
                    support@sagri.org
                  </NavLink>
                  <NavLink href="mailto:donation@sagri.org" className="email">
                    donation@sagri.org
                  </NavLink>
                </div>
              </div>
              <div className="col">
                <div className="company-info">
                  <FontAwesomeIcon icon={faClock} />
                  <h3>24/7 Support</h3>
                  <span>
                    24/7 Ready Our Support Team For You. Day to night.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-sec-wrap margin-bottom-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>Contact With Us</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <div
                        className={`form-group input ${
                          errors.fname && "error-field"
                        }`}
                      >
                        <label>Your Name*</label>
                        <input
                          type="text"
                          placeholder="Your Name*"
                          className="generic-field"
                          {...register("fname", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9]+[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]*[a-zA-Z0-9]*/i,
                            maxLength: 50,
                          })}
                        />
                        {errors?.fname?.type === "required" && (
                          <p className="error-msg">This field is required</p>
                        )}
                        {errors?.fname?.type === "pattern" && (
                          <p className="error-msg">
                            Alphanumeric and special characters are allowed
                          </p>
                        )}
                        {errors?.fname?.type === "maxLength" && (
                          <p className="error-msg">
                            Maximum 50 characters are allowed
                          </p>
                        )}
                      </div>
                    </li>
                    <li>
                      <div
                        className={`form-group input ${
                          errors.phone && "error-field"
                        }`}
                      >
                        <label>Your Phone Number*</label>
                        <input
                          type="tel"
                          placeholder="Your Phone Number 0300-0000000*"
                          className="generic-field"
                          {...register("phone", {
                            required: true,
                            pattern: /^[0-9-]+$/,
                            minLength: 12,
                          })}
                        />
                        {errors?.phone?.type === "required" && (
                          <p className="error-msg">This field is required</p>
                        )}
                        {errors?.phone?.type === "pattern" && (
                          <p className="error-msg">Please enter a number</p>
                        )}
                        {errors?.phone?.type === "minLength" && (
                          <p className="error-msg">
                            Minimum 12 characters are allowed
                          </p>
                        )}
                      </div>
                    </li>
                    <li>
                      <div
                        className={`form-group input ${
                          errors.email && "error-field"
                        }`}
                      >
                        <label>Email address*</label>
                        <input
                          type="text"
                          placeholder="i.e. example@example.com"
                          className="generic-field"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />
                        {errors?.email?.type === "required" && (
                          <p className="error-msg">This field is required</p>
                        )}
                        {errors?.email?.type === "pattern" && (
                          <p className="error-msg">Invalid email address</p>
                        )}
                      </div>
                    </li>
                    <li>
                      <div
                        className={`form-group input ${
                          errors.subject && "error-field"
                        }`}
                      >
                        <label>Subject*</label>
                        <input
                          type="text"
                          placeholder="Subject"
                          className="generic-field"
                          {...register("subject", {
                            required: true,
                          })}
                        />
                        {errors?.subject?.type === "required" && (
                          <p className="error-msg">This field is required</p>
                        )}
                       
                      </div>
                    </li>
                    <li className="last">
                      <div
                        className={`form-group input ${
                          errors.textfield && "error-field"
                        }`}
                      >
                        <label>Your Message*</label>
                        <textarea
                          type="text-area"
                          placeholder="Your Message"
                          className="generic-field text-area-field"
                          {...register("textfield", {
                            required: true,
                          })}
                        />
                        {errors?.textfield?.type === "required" && (
                          <p className="error-msg">This field is required</p>
                        )}
                      </div>
                    </li>
                    <input
                      type="submit"
                      value="Submit"
                      className="generic-btn generic-input-btn"
                    />
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="map-sec-wrap">
          <div className="container">
            <div className="row">
              <div className="col">
                <h3>Pakistan Office</h3>
                <PakMap />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default Contact;
