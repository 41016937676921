import Carousel from 'react-bootstrap/Carousel';
import React from "react";
import '../Slider/slider.css';
import Banner_1 from "../../images/banner-1.jpg";
import Banner_2 from "../../images/banner-2.jpg";
import Banner_3 from "../../images/banner-3.jpg";


function Slider() {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="banner-effect" style={{ backgroundImage: `url(${Banner_1})` }}>
        {/* <img src={Banner_1} alt="Banner"/> */}
        </div>
        <Carousel.Caption>
        <h2>WELCOME TO</h2>
          <h3>Dewaan Welfare Society</h3>
          <p>Our beloved Prophet Hazrat Muhammed (Peace Be Upon Him) said "The Person who wants his difficulties to be finished and his prayers to get accepted, then he should help the needy and people in problems." From the saying of Holy Prophet Hazrat Muhammed (Peace Be Upon Him) we come to know that helping the needy and ecomically week people, wether its is done alone or in group, it is the very valuable in sight of Allah.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="banner-effect" style={{ backgroundImage: `url(${Banner_2})` }}>
        {/* <img src={Banner_2} alt="Banner"/> */}
        </div>
        <Carousel.Caption>
          <h2>WELCOME TO</h2>
          <h3>Dewaan Welfare Society</h3>
          <p>Our beloved Prophet Hazrat Muhammed (Peace Be Upon Him) said "The Person who wants his difficulties to be finished and his prayers to get accepted, then he should help the needy and people in problems." From the saying of Holy Prophet Hazrat Muhammed (Peace Be Upon Him) we come to know that helping the needy and ecomically week people, wether its is done alone or in group, it is the very valuable in sight of Allah.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="banner-effect" style={{ backgroundImage: `url(${Banner_3})` }}>
        {/* <img src={Banner_3} alt="Banner"/> */}
        </div>
        <Carousel.Caption>
        <h2>WELCOME TO</h2>
          <h3>Dewaan Welfare Society</h3>
          <p>Our beloved Prophet Hazrat Muhammed (Peace Be Upon Him) said "The Person who wants his difficulties to be finished and his prayers to get accepted, then he should help the needy and people in problems." From the saying of Holy Prophet Hazrat Muhammed (Peace Be Upon Him) we come to know that helping the needy and ecomically week people, wether its is done alone or in group, it is the very valuable in sight of Allah.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
export default Slider;
