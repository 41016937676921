import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import donationsListingImg from "../../images/2014/donations/donations_listing_2014.png";
import OverviewImg2 from "../../images/overview-img-2.jpg";
import OverviewImg3 from "../../images/overview-img-3.jpg";

function OverviewDetail2014() {
  return (
    <>
    <TopBanner PageName="Overview Images"></TopBanner>
    <div className="main-sec">
      <div className="overview-detail text-center">
        <div className="container">
         <div className="row">
           <div className="col">
              <a href={donationsListingImg} data-lightbox="photos" data-gallery="gallery">
              <img src={donationsListingImg} alt="Plant img"/>
              </a>
           </div>
           {/* <div className="col">
              <a href={OverviewImg2} data-lightbox="photos" data-gallery="gallery">
              <img src={OverviewImg2} alt="Plant img"/>
              </a>
           </div>
           <div className="col">
              <a href={OverviewImg3} data-lightbox="photos" data-gallery="gallery">
              <img src={OverviewImg3} alt="Plant img"/>
              </a>
           </div> */}
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default OverviewDetail2014;
