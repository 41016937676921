import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import counter_icon_1 from "../../images/counter-icon-1.png";
import counter_icon_2 from "../../images/counter-icon-2.png";
import counter_icon_3 from "../../images/counter-icon-3.png";
import counter_icon_4 from "../../images/counter-icon-4.png";

function CurrentServices() {
  return (
    <>
      <TopBanner PageName="Current Services"></TopBanner>
      <div className="main-sec">
        <div className="counter-sec-wrap margin-bottom-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="counter-sec text-center box-shadow">
                  <img src={counter_icon_1} alt="Counter-icon" />
                  <h4 className="text-green">50</h4>
                  <span>
                    Help to deserving students for higher education i.e College
                    & University
                  </span>
                </div>
              </div>
              <div className="col">
                <div className="counter-sec text-center box-shadow">
                  <img src={counter_icon_2} alt="Counter-icon" />
                  <h4 className="text-green">50</h4>
                  <span>
                    Rashan/Salary for deserving people on monthly basis
                  </span>
                </div>
              </div>
              <div className="col">
                <div className="counter-sec text-center box-shadow">
                  <img src={counter_icon_3} alt="Counter-icon" />
                  <h4 className="text-green">50</h4>
                  <span>Full arrangement of dowry for deserving people</span>
                </div>
              </div>
              <div className="col">
                <div className="counter-sec text-center box-shadow">
                  <img src={counter_icon_4} alt="Counter-icon" />
                  <h4 className="text-green">100</h4>
                  <span>
                    Managing a doctors visit on weekly or monthly basis for free
                    medical camp
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="services-top-wrap" id="about-us">
          <div className="container">
            <div className="row">
              <h4>About Us</h4>
              <h1>A Dream In Their Mind Is Our Mission</h1>
              <h3 className="orange-color">About Deewan Welfare Society</h3>
              <p>
                ur beloved Prophet Hazrat Muhammed (Peace Be Upon Him) said "The
                Person who wants his difficulties to be finished and his prayers
                to get accepted, then he should help the needy and people in
                problems." From the saying of Holy Prophet Hazrat Muhammed
                (Peace Be Upon Him) we come to know that helping the needy and
                ecomically week people, wether its is done alone or in group, it
                is the very valuable in sight of Allah.
              </p>
              <p>
                Sagri is located 5 kilometers North East from crowded and noisy
                environment of city Dina. ts a quite suitable place for
                residence , as basic facilities and communication is easily
                available. Village touches mountains in east , mangla dam is 10
                kilometer north. and Rohtas Fort build by Sher Shah Soori is 10
                kilometer in south from Dina, River Jehlum lies in west about 4
                Km away, and the capital of Pakistan Islamabad is on 1 Hour
                drive. The Village Sagri was laid in 15th century according to
                different researches. The first person to stay here was Sagar
                Khan. The Village was aslo named "Sagri" after his name. His son
                was Gujjar Khan and grand sona was Muhammed Hayat. Hazrat Shah
                Fateh Deewan Sahb (R.A) gave Muhammad Hayat title of "Mian".
                Muhammad Hayat had four sons, Faizullah , Atiqullah, Sher
                Muhammad, Sultan Muhammad. Childre of these four sons was the
                initial population of this population. As time passed families
                from surrounding areas also came and settled in the village.
                According to an estimate now village has 485 homes.
              </p>
              <p>
                With the efforts of peoples of the village basic faciilities in
                village kept increasing slowly. But major develpments were made
                in 1978 after the visit of President of Pakistan Late Gen. Zia
                Ul Haq , this visit was arranged on the death of Mr. Feroz Khan
                father of Military Secretary Brigadeir Muhammad Younas. On this
                occassion he met the Local Personels and on their demand for
                basic facilties issued following orders: With the efforts of
                peoples of the village basic faciilities in village kept
                increasing slowly. But major develpments were made in 1978 after
                the visit of President of Pakistan Late Gen. Zia Ul Haq , this
                visit was arranged on the death of Mr. Feroz Khan father of
                Military Secretary Brigadeir Muhammad Younas. On this occassion
                he met the Local Personels and on their demand for basic
                facilties issued following orders: With the efforts of peoples
                of the village basic faciilities in village kept increasing
                slowly. But major develpments were made in 1978 after the visit
                of President of Pakistan Late Gen. Zia Ul Haq , this visit was
                arranged on the death of Mr. Feroz Khan father of Military
                Secretary Brigadeir Muhammad Younas. On this occassion he met
                the Local Personels and on their demand for basic facilties
                issued following orders:
              </p>
              <p>
                Our beloved Prophet Hazrat Muhammed (Peace Be Upon Him) said
                "The Person who wants his difficulties to be finished and his
                prayers to get accepted, then he should help the needy and
                people in problems." From the saying of Holy Prophet Hazrat
                Muhammed (Peace Be Upon Him) we come to know that helping the
                needy and ecomically week people, wether its is done alone or in
                group, it is the very valuable in sight of Allah
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CurrentServices;
