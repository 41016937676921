import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";

function Overview() {
  const donationsSummary = [
    {'year':2021,'recieved':1011659,'expensed':954790,'detailsPage':'/#/overview-detail/2021'},
    {'year':2020,'recieved':0,'expensed':0},
    {'year':2019,'recieved':2365,'expensed':8798},
    {'year':2012,'recieved':1784748,'expensed':1715868},
    {'year':2014,'recieved':283896,'expensed':230967,'detailsPage':'/#/overview-detail/2014'},
    {'year':2011,'recieved':283896,'expensed':177367},
    {'year':2007,'recieved':19600,'expensed':12330},
    {'year':2006,'recieved':14630,'expensed':10900,},
    {'year':2005,'recieved':3260,'expensed':2500,},
    {'year':2004,'recieved':5129,'expensed':500,},
    {'year':2003,'recieved':2510,'expensed':	9360,}
  ]
  return (
    <>
      <TopBanner PageName="Overview"></TopBanner>
      <div className="main-sec">
        <div className="overview-main-wrap">
          <div className="container">
            <div className="row">
              <p>We are very thankful to all our respected donors. Our organization has been working actively since 2002. Each year organisation has been able to perform better by making more collections and helping more people.</p>
              <p>Following is an overview of the progress till now :</p>
              <div className="overview-table">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">SR#</th>
                      <th scope="col">YEAR</th>
                      <th scope="col">Donations Recieved</th>
                      <th scope="col">Donations Expensed</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    donationsSummary.map((currDonationSummary,index) => (  
                    <>
                    <tr>
                      <td>{index+1}</td>
                      <td>{currDonationSummary.year}</td>
                      <td>{currDonationSummary.recieved}</td>
                      <td>{currDonationSummary.expensed}</td>
                       {  currDonationSummary.detailsPage === undefined?<td></td>: <td><a href={currDonationSummary.detailsPage}>Details</a></td>} 
                    </tr>
                    </>
                    ))}
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Overview;
