import React from "react";
import '../Inner-banner/banner.css';

function TopBanner(props) {
  
  return (
    <div className="top-banner">
      <div className="container">
        <div className="banner-inner-wrap">
          <h1>{props.PageName}</h1>
          <a href="/">Home</a> | <span>{props.PageName}</span>
        </div>
      </div>
    </div>
  );
}
export default TopBanner;
