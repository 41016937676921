import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarSide,
  faStore,
  faSwimmer,
} from "@fortawesome/free-solid-svg-icons";
import TopBanner from "../../Component/Inner-banner/banner";
import { Link } from "react-router-dom";

function CompletedProject() {
  return (
    <>
      <TopBanner PageName="Completed Project"></TopBanner>
      <div className="main-sec">
        <div className="project-top-wrap">
          <div className="container">
            <div className="row">
              <h2>Deewan Welfare Society Projects</h2>
              <p>Purposes of Establishment of Dewaan Welfare Society:</p>
              <ul>
                <li>
                  To provide Monetary Help available for Widowed and Unassisted
                  women
                </li>
                <li>Help economically weak families.</li>
                <li>Monetary help for poor and impecunious.</li>
                <li>
                  Monetary help on occasion of marriages of orphan and deserving
                  girls.
                </li>
                <li>
                  Monetary help in educational expense of orphan and deserving
                  students.
                </li>
                <li>
                  Helping students acquiring education on college and university
                  level.
                </li>
                <li>
                  Providing ambulance at Basic health Unit for the patients.
                </li>
                <li>
                  Help in arrangement of burial service on death of a poor
                  person.
                </li>
                <li>Establishment of Islamic Library.</li>
                <li>
                  Arrangement of facility of cleanliness of village streets and
                  surroundings.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-box-wrap">
          <div className="container">
            <div className="row">
              <h1>Completed Projects</h1>
              <div className="col">
                <div className="service-box-inner">
                  <Link to="/project-detailed/?projectid=ILP">
                    <FontAwesomeIcon icon={faStore} />
                    <h2>Establishment of Islamic Library</h2>
                  </Link>
                </div>
                
              </div>
              <div className="col">
                <div className="service-box-inner">
                <Link to="/project-detailed/?projectid=SCP">
                  <FontAwesomeIcon icon={faSwimmer} />
                  <h2>
                    Construction of several main streets of village from 2008 &
                    2014
                  </h2>
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                <Link to="/project-detailed/?projectid=AP">
                  <FontAwesomeIcon icon={faCarSide} />
                  <h2>Arrangement of Ambulance in year 2013</h2>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CompletedProject;
