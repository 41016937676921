import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faMale } from "@fortawesome/free-solid-svg-icons";
import TopBanner from "../../Component/Inner-banner/banner";

function Donate() {
  return (
    <>
      <TopBanner PageName="Donate"></TopBanner>
      <div className="main-sec extra-space">
        <div className="services-box-wrap">
          <div className="container">
            <div className="row">
              <h1>How to Donate</h1>
              <p>You can donate using following methods</p>
              <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faDollarSign} />
                  <h2>Donation Channels</h2>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faMale} />
                  <h2>Become a Member</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Donate;
