import React from "react";
import "../Footer/footer.css";

function Footer() {
  return (
    <footer className="footer green-bg">
      <div className="footer-wrap">
        <p>Copyright By@Dewansagri - 2021</p>
      </div>
    </footer>
  );
}
export default Footer;
