import React from "react";

import About_img from "../../images/about-img.jpg";
import Edu_icon from "../../images/edu-icon.png";
import Home_icon from "../../images/home-icon.png";
import Food_icon from "../../images/food-icon.png";
import Med_icon from "../../images/medicine-icon.png";
import "../Home/home.css";
import Slider from "../../Component/Slider/slider";

function Home() {
  const donationsSummary = [
    {'year':2021,'recieved':1011659,'expensed':954790,'detailsPage':'/#/overview-detail/2021'},
    {'year':2020,'recieved':0,'expensed':0},    
    {'year':2019,'recieved':2365,'expensed':8798},
    {'year':2012,'recieved':1784748,'expensed':1715868},
    // {'year':2014,'recieved':283896,'expensed':230967,'detailsPage':'/#/overview-detail/2014'},
    // {'year':2011,'recieved':283896,'expensed':177367},
    // {'year':2007,'recieved':19600,'expensed':12330},
    // {'year':2006,'recieved':14630,'expensed':10900,},
    // {'year':2005,'recieved':3260,'expensed':2500,},
    // {'year':2004,'recieved':5129,'expensed':500,},
    // {'year':2003,'recieved':2510,'expensed':	9360,}
  ]
  return (
    <>
      <div className="main-banner">
        {/* <img src={Banner} alt="Banner" /> */}
        <Slider/>
      </div>
        <div className="counter-sec-wrap margin-bottom-80">
          <div className="container">
          <div className="row">
            { donationsSummary.map((currDonationSummary,index) => (  
                    <>
                    
              <div className="col">
                <div className="counter-sec text-center box-shadow">
                  <a href={currDonationSummary.detailsPage??'/'}>
                  <h4 className="text-green">RS {currDonationSummary.recieved.toLocaleString()}</h4>
                  <span>Donations Received in {currDonationSummary.year}</span>
                  </a>
                </div>
              </div>
            
            </>
            ))}
            </div>
          </div>
        </div>
        <div className="home-inner-sec margin-bottom-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="tech-about">
                  <h2>ABOUT US</h2>
                  <h3>
                    Dewaan Welfare Society
                  </h3>
                  <p>
                    Our beloved Prophet Hazrat Muhammed (Peace Be Upon Him) said "The Person who wants his difficulties to be finished and his prayers to get accepted, then he should help the needy and people in problems." From the saying of Holy Prophet Hazrat Muhammed (Peace Be Upon Him) we come to know that helping the needy and ecomically week people, wether its is done alone or in group, it is the very valuable in sight of Allah.
                  </p>
                  <a href="/current-services#about-us" className="generic-btn">Learn More</a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 text-center">
                <div className="about-img">
                  <img src={About_img} alt="About Us" className="box-shadow"/>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="donate-wrap-sec margin-bottom-80">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 sm-text-center">
                  <h2>GET STARTED TODAY.</h2>
                  <h3>Be A Part Of The Proud <br></br>Partner.</h3>
                </div>
                <div className="col-lg-6 col-md-12 sm-text-center">
                  <a href="/donation-channels/" className="generic-btn right">Donate Now</a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-sec-main-wrap margin-bottom-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="tech-about">
                  <h2>DEEWAN WELFARE SOCIETY PROJECTS</h2>
                  <h3>
                    Purposes Of Establishment Of Dewaan Welfare Society:
                  </h3>
                  <div className="desc"><ul><li>To provide Monetary Help available for Widowed and Unassisted women.</li><li>Help economically weak families.</li><li>Monetary help for poor and impecunious.</li><li>Monetary help on occasion of marriages of orphan and deserving girls.</li><li>Monetary help in educational expense of orphan and deserving students.</li><li>Helping students acquiring education on college and university level.</li><li>Providing ambulance at Basic health Unit for the patients.</li><li>Help in arrangement of burial service on death of a poor person.</li><li>Establishment of Islamic Library.</li><li>Arrangement of facility of cleanliness of village streets and surroundings.</li></ul></div>
                  <a href="/current-project/" className="generic-btn">Learn More</a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="services-sec-wrap">
                  <div className="services-sec box-shadow">
                    <div className="service-icon">
                      <img src={Edu_icon} alt="Education" />
                    </div>
                    <div className="service-content">
                      <h4>Educations For Children</h4>
                      <a href="/" className="text-green">Read More</a>
                    </div>
                  </div>
                  <div className="services-sec box-shadow">
                    <div className="service-icon">
                      <img src={Home_icon} alt="Home" />
                    </div>
                    <div className="service-content">
                      <h4>Home For Homeless People</h4>
                    </div>
                  </div>
                  <div className="services-sec box-shadow">
                    <div className="service-icon">
                      <img src={Food_icon} alt="Food" />
                    </div>
                    <div className="service-content">
                      <h4>Food For Hungry Peoples</h4>
                    </div>
                  </div>
                  <div className="services-sec box-shadow">
                    <div className="service-icon">
                      <img src={Med_icon} alt="Medicine" />
                    </div>
                    <div className="service-content">
                      <h4>Medicine For Poor People</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
    </>
  );
}
export default Home;
