import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import donationsListingImg from "../../images/2021/donations/donations_listing_2021.jpg";


function OverviewDetail2021() {
  const imagesList = ["/images/2021/donations/donations_listing_2021.jpg","/images/2021/donations/general_exepnses_2021.jpg","/images/2021/donations/ambulance_expenses_2021.jpg"]
  return (
    <>
    <TopBanner PageName="Overview Images"></TopBanner>
    <div className="main-sec">
      <div className="overview-detail text-center">
        <div className="container">
         <div className="row">
         { imagesList.map((currImage,index) => (  
                    <>
           <div className="col">
              <a href={window.location.origin.toString() + currImage} data-lightbox="photos" data-gallery="gallery">
              <img src={currImage} alt="Plant img"/>
              </a>
           </div>
           </>))
          }
           {/* <div className="col">
              <a href={OverviewImg2} data-lightbox="photos" data-gallery="gallery">
              <img src={OverviewImg2} alt="Plant img"/>
              </a>
           </div>
           <div className="col">
              <a href={OverviewImg3} data-lightbox="photos" data-gallery="gallery">
              <img src={OverviewImg3} alt="Plant img"/>
              </a>
           </div> */}
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default OverviewDetail2021;
