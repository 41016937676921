import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import TopBanner from "../../Component/Inner-banner/banner";

function Organization() {
  return (
    <>
      <TopBanner PageName="Organizational Structure"></TopBanner>
      <div className="main-sec">
        <div className="services-box-wrap">
          <div className="container">
            <div className="row">
              <h1>Organizational Structure</h1>
              <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faMale} />
                  <h2>Anwar Ul Haq</h2>
                  <p>Planning &amp; Management Designation</p>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faMale} />
                  <h2>Khurram</h2>
                  <p>Finance Department Designation</p>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faMale} />
                  <h2>Tayyab Ali</h2>
                  <p>Planning &amp; Management Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Organization;
