import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";

function DonationChannels() {
  return (
    <>
      <TopBanner PageName="Donation Channels"></TopBanner>
      <div className="main-sec">
        <div className="donation-channels">
          <div className="container">
            <div className="row">
              <h1>Donation Channels</h1>
              <h2>Bank Details</h2>
              <span>Bank Name: Muslim Commercial Bank (MCB)</span>
              <span>A/C Title: Deewan Welfare Society</span>
              <span>A/C No: Pk 84 MUCB 0516013021000683</span>
              <span>Branch: Branch no.0656 MCB Nokoder.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DonationChannels;
