import React from "react";




   const TehfeezUlQuran = {
    "ProjectName":"Tehfeez Ul Quran",
    "Description" : "Students are educated quran.",
    "GalleryTitle":"Tehfeez Ul Quran Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
        {
            "FolderName":"2023",
            "Year":2023,
            "Details":"This folder contains images taken during classes of tehfeez ul quran in 2023",
            "BaseUrl":"images/tehfeez-ul-quran/2023",
            "Images":[
                {Title:"Tehfeez ul quran class 1",Path: "tuq-1.jpeg"},
                {Title:"Tehfeez ul quran class 2",Path: "tuq-2.jpeg"},
                {Title:"Tehfeez ul quran class 3",Path: "tuq-3.jpeg"},
                {Title:"Tehfeez ul quran class 4",Path: "tuq-4.jpeg"},
                {Title:"Tehfeez ul quran class 5",Path: "tuq-5.jpeg"},
                             
               
            ]
        }
       ],
    "Documents":[
       
       
    ]
   };

   
   
export default TehfeezUlQuran;
