import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPhotoVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import TopBanner from "../../Component/Inner-banner/banner";
import ProjectsDetails from "../../Repositories/projects-repository"
import { Link } from "react-router-dom";
function ProjectDetailed() {

  let [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("projectid");
  let projectId = new URLSearchParams(searchParams).get('projectid');

 

  return (
    <>
      <TopBanner PageName="Project Details"></TopBanner>
      <div className="main-sec">
        
        <div className="services-box-wrap">
          <div className="container">
            <div className="row">
            {
                ProjectsDetails[projectId] !== undefined  ? ( 
                  <>
              <h1>Project Details</h1>
              
              <p>{ ProjectsDetails[projectId].Description} </p>
              {ProjectsDetails[projectId].Documents !== undefined && ProjectsDetails[projectId].Documents.length > 0 &&
              <div className="col">
                <div className="service-box-inner">
                <FontAwesomeIcon icon={faFilePdf} />
                  <p>
                  <Link to={"/project-documents/?projectid="+projectId}>
                  View Documents
                  </Link>
                  </p>
                </div>
              </div>
}
{ 
                 
                 ProjectsDetails[projectId].ImagesGallery !== undefined && ProjectsDetails[projectId].ImagesGallery.length > 0 &&
              <div className="col">
                <div className="service-box-inner">
                <FontAwesomeIcon icon={faPhotoVideo} />
                  <p>
                 
                    <Link to={"/project-gallery/?projectid="+projectId}>
                    Project Gallery </Link>
                 
               
                   </p>
                </div>
              </div> } </>): <h1>Project Not Found</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProjectDetailed;
