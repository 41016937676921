import React from "react";




   const Ambulance = {
    "ProjectName":"Ambulance",
    "Description" : "Purpose of this initiative is to provide emergency service for free to .",
    "GalleryTitle":"Ambulance Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
        {
            "FolderName":"2014",
            "Year":2014,
            "Details":"In 2014 pictures were taken.",
            "BaseUrl":"images/ambulance/2014",
            "Images":[
                {Title:"Ambulance Vehicle 1",Path: "ambulance-vehicle-1.png"},
                {Title:"Ambulance Vehicle 2",Path: "ambulance-vehicle-2.png"},
                {Title:"Ambulance Vehicle 3",Path: "ambulance-vehicle-3.png"}
               
            ]
        }
       ],
    "Documents":[
        {
            "FolderName":"Expense",
            "Year":2021,
            "Details":"This folders contains documents of details of expnses and funds.",
            "BaseUrl":"documents/ambulance/expense/2021",
            "Links":[
                {Title:"Project Expense and Funds","Url":"month-wise-fund-exp-2021.png","Type":"image"},
               
            ],
        },
        {
            "FolderName":"General",
            "Year":2021,
            "Details":"This folders contains documents of details of expnses and funds.",
            "BaseUrl":"documents/ambulance/expense/general",
            "Links":[
                {Title:"Project Expense and Funds","Url":"year-wise-fund-exp-summar-2014-2020.png","Type":"image"},
               
            ],
        }
       
    ]
   };

   
   
export default Ambulance;
