import React from "react";
import '../Header/header.css';
import Logo from '../../images/logo.png';
import { NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="logo">
              <NavLink href="/"><img src={Logo} alt="Logo" /></NavLink>
            </div>
          </div>
          <div className="col">
            <div className="header-right-sec">
              <NavLink href="tel:+923455708423"><FontAwesomeIcon icon={faPhoneAlt} />+92-345-5708423</NavLink>
              <NavLink href="mailto:inquire@sagri.org" className="email"><FontAwesomeIcon icon={faEnvelope} />inquire@sagri.org</NavLink>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;