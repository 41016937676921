import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlind,
  faCartPlus,
  faClone,
  faDollarSign,
  faDragon,
  faStore,
  faTree,
} from "@fortawesome/free-solid-svg-icons";
import TopBanner from "../../Component/Inner-banner/banner";
import { Link } from "react-router-dom";

function CurrentProject() {
  return (
    <>
      <TopBanner PageName="Current Project"></TopBanner>
      <div className="main-sec">
        <div className="project-top-wrap">
          <div className="container">
            <div className="row">
              <h2>Deewan Welfare Society Projects</h2>
              <p>Purposes of Establishment of Dewaan Welfare Society:</p>
              <ul>
                <li>
                  To provide Monetary Help available for Widowed and Unassisted
                  women
                </li>
                <li>Help economically weak families.</li>
                <li>Monetary help for poor and impecunious.</li>
                <li>
                  Monetary help on occasion of marriages of orphan and deserving
                  girls.
                </li>
                <li>
                  Monetary help in educational expense of orphan and deserving
                  students.
                </li>
                <li>
                  Helping students acquiring education on college and university
                  level.
                </li>
                <li>
                  Providing ambulance at Basic health Unit for the patients.
                </li>
                <li>
                  Help in arrangement of burial service on death of a poor
                  person.
                </li>
                <li>Establishment of Islamic Library.</li>
                <li>
                  Arrangement of facility of cleanliness of village streets and
                  surroundings.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-box-wrap">
          <div className="container">
            <div className="row">
              <h1>Current Projects</h1>
              <div className="col">
                <div className="service-box-inner">
                  <Link to="/project-detailed/?projectid=HDS">
                    <FontAwesomeIcon icon={faStore} />
                    <p>
                      Help of deserving students for education
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                  <Link to="/project-detailed/?projectid=ERP">
                    <FontAwesomeIcon icon={faDollarSign} />
                    <p>Eid/Ramzan Package for deserving people</p>
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                  <Link to="/project-detailed/?projectid=DWC">
                    <FontAwesomeIcon icon={faCartPlus} />
                    <p>Free Medical Dispensary 24/7 for people of village</p>
                  </Link>
                </div>
              </div>
             
             <div className="col">
                <div className="service-box-inner">
                  <Link to="/project-detailed/?projectid=BTSP">
                    <FontAwesomeIcon icon={faTree} />
                    <h2>Plantation</h2>
                    <p>
                      To support government in project of 100 billion tree sonami
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="service-box-inner">
                  <Link to="/project-detailed/?projectid=TUQ">
                    <FontAwesomeIcon icon={faTree} />
                    <h2>Tehfeez Ul Qur'an</h2>
                    <p>
                      Education of quran
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CurrentProject;
