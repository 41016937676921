import React from "react";




   const TreePlantation = {
    "ProjectName":"Tree Plantation",
    "Description" : "Purpose of this initiative is to help  government achieve billion tree sonami mile stone.",
    "GalleryTitle":"Tree Plantation Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
        {
            "FolderName":"2021",
            "Year":2021,
            "Details":"In 2021 trees were planted in surroundings of village sagri",
            "BaseUrl":"images/tree-plantation/2021",
            "Images":[
                {Title:"Planting 1",Path: "planting-1.png"},
                {Title:"Planting 2",Path: "planting-2.png"},
                {Title:"Planting 3",Path: "planting-3.png"},
                {Title:"Planting 4",Path: "planting-4.png"}
               
            ]
        }
       ],
    "Documents":[
        {
            "FolderName":"Expense",
            "Year":2021,
            "Details":"In 2021 trees were planted in surroundings of village sagri. This folder contains images and documents of details of expnses and funds",
            "BaseUrl":"documents/tree-plantation/expense/2021",
            "Links":[
                {Title:"Project Expense and Funds","Url":"month-wise-fund-exp-2021.png","Type":"image"},
               
            ],
        },
       
    ]
   };

   
   
export default TreePlantation;
