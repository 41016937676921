import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import { useSearchParams } from "react-router-dom";
import ProjectsDetails from "../../Repositories/projects-repository"
import PlantationImg1 from "../../images/plantation-gallery-img1.jpg";
import PlantationImg2 from "../../images/plantation-gallery-img2.jpg";
import PlantationImg3 from "../../images/plantation-gallery-img3.jpg";
import PlantationImg4 from "../../images/plantation-gallery-img4.jpg";
import PlantationImg5 from "../../images/plantation-gallery-img5.jpg";
import PlantationImg6 from "../../images/plantation-gallery-img6.jpg";
import PlantationImg7 from "../../images/plantation-gallery-img7.jpg";

function ProjectYearWiseGallery() {
   let [searchParams, setSearchParams] = useSearchParams();
  //searchParams.get("year");
  let projectId = new URLSearchParams(searchParams).get('projectid');
  //searchParams.get("year");
  let year = new URLSearchParams(searchParams).get('year');
  return (
    <>
    <TopBanner PageName={ProjectsDetails[projectId].GalleryTitle}></TopBanner>
    <div className="main-sec">
      <div className="plantaion-gallery text-center">
        <div className="container">
         <div className="row">
         {ProjectsDetails[projectId].ImagesGallery.map(name => (  
            
            name.Year == year && <>
               <h1>{name.FolderName}</h1>
              {  name.Images.map(currImage => (
                <>
               <div className="col">
                  <a href={name.BaseUrl +"/"+  currImage.Path} data-lightbox="photos" data-gallery="gallery">
                  <img src={name.BaseUrl +"/"+ currImage.Path} alt="Plant img"/>
                  <h4>{currImage.Title}</h4>
                  </a>
               </div>
               </>
            
              ))}
            </>
         ))}
           
           
           {/* <div className="col">
              <a href={PlantationImg2} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg2} alt="Plant img"/>
              <h4>Planting</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg3} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg3} alt="Plant img"/>
              <h4>Prayer 2</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg4} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg4} alt="Plant img"/>
              <h4>Prayer</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg5} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg5} alt="Plant img"/>
              <h4>Safety</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg6} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg6} alt="Plant img"/>
              <h4>Transport</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg7} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg7} alt="Plant img"/>
              <h4>Planting 2</h4>
              </a>
           </div> */
           }
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default ProjectYearWiseGallery;
