import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import FebImg1 from "../../images/feb-img-1.jpg";
import FebImg2 from "../../images/feb-img-2.jpg";
import FebImg3 from "../../images/feb-img-3.jpg";
import FebImg4 from "../../images/feb-img-4.jpg";

function FebImages() {
  return (
    <>
    <TopBanner PageName="Feb Images"></TopBanner>
    <div className="main-sec">
      <div className="monthly-pictures-sec text-center">
        <div className="container">
         <div className="row">
           <h1>Dewan welfare society Tree plantition in sagri main graveyard. 2020</h1>
           <div className="col">
             <a href="/plantation-gallery/">
              <img src={FebImg1} alt="Plant img"/>
              </a>
           </div>
           <div className="col">
             <a href="/plantation-gallery/">
              <img src={FebImg2} alt="Plant img"/>
              </a>
           </div>
           <div className="col">
             <a href="/plantation-gallery/">
              <img src={FebImg3} alt="Plant img"/>
              </a>
           </div>
           <div className="col">
             <a href="/plantation-gallery/">
              <img src={FebImg4} alt="Plant img"/>
              </a>
           </div>
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default FebImages;
