import React from "react";




   const IslamicLibrary = {
    "ProjectName":"Islamic Library",
    "Description" : "Purpose of this initiative was to create a source of knowledge by creating a collection of valid books. These books are related of Islamic education and history. ",
    "GalleryTitle":"",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
       
       ],
    "Documents":[
       
       
    ]
   };

   
   
export default IslamicLibrary;
