import React from "react";
import Iframe from 'react-iframe'

function PakMap() {
  return (
    <div className="map-sec">
      <Iframe
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d427329.50607993745!2d73.89794581769367!3d33.200677190843614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sDeewan%20Welfare%20Society%20Jhelum!5e0!3m2!1sen!2sus!4v1649665071524!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
      ></Iframe>
    </div>
  );
}
export default PakMap;