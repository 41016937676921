import React from "react";




   const StreetConstruction = {
    "ProjectName":"Street Construction",
    "Description" : "Sevaral street of village sagri were reconstructed.",
    "GalleryTitle":"Street Construction Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
        {
            "FolderName":"General",
            "Year":2021,
            "Details":"This folder contains images taken during construction of streets durint this project",
            "BaseUrl":"images/street-construction/general",
            "Images":[
                {Title:"Street beside GGHS",Path: "street-construction-beside-gghs.png"},
                {Title:"Streetin front of masjid",Path: "street-construction-masjid-front.png"},
                {Title:"Milad chowk street",Path: "street-construction-milad-chowk.png"},
              
               
            ]
        }
       ],
    "Documents":[
        {
            "FolderName":"Project Details",
            "Year":2021,
            "Details":"This folder contain files regarding project details.",
            "BaseUrl":"documents/street-construction/general",
            "Links":[
                {Title:"Project Details","Url":"project-details.docx","Type":"docx"},
               
            ],
        },
       
    ]
   };

   
   
export default StreetConstruction;
