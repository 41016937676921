import React from "react";
import Ambulance from "./ambulance-project";
import EidRamzanPackages from "./eid-ramzan-packages-project";
import IslamicLibrary from "./islamic-library-project-repo";
import StreetConstruction from "./street-construction-project";
import TehfeezUlQuran from "./tehfeez-ul-quran";
import TreePlantation from "./tree-plantation-project";


const DewanWelfareClinic = {
    "ProjectName":"Dewan Welfare Clinic",
    "Description" : "Dewan Welfare Clinic is augrated in year 2019. Purpose of clinic is to provide free and quality medical services to needy people. People who can afford will have to pay set checkup fee by the management.",
    "GalleryTitle":"Dewan Welfare Clinic Image Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
        {
        "FolderName":"2019",
        "Year":2019,
        "Details":"In 2019 welfare clinic was build and augrated, this gallery has image of development and Inaugration",
        "BaseUrl":"images/welfare-clinic/gallery/2019",
        "Images":[
            {Title:"Development 1",Path: "development-1.png"},
            {Title:"Development 2",Path: "development-2.png"},
            {Title:"Development 3",Path: "development-3.png"},
            {Title:"Inaugration 1",Path: "inaugration-1.png"},
            {Title:"Inaugration 2",Path: "inaugration-2.png"},
            {Title:"Inaugration 3",Path: "inaugration-3.png"},
            {Title:"Inaugration 4",Path: "inaugration-4.png"},
            {Title:"Inaugration 5",Path: "inaugration-5.png"}
        ]
        },
        
],
    "Documents":[
        {
            "FolderName":"Expense 2020",
            "Year":2020,
            "Details":"Details of funds collected and expnsed in year 2020",
            "BaseUrl":"documents/welfare-clinic/expense/2020",
            "Links":[
                {Title:"Monthly expense","Url":"expense-statement-by-month.png","Type":"image"},
                {Title:"Expense Phase 1","Url":"expnse-detail-phase-1.png","Type":"image"},
                {Title:"Expense Phase 2","Url":"expnse-detail-phase-2.png","Type":"image"},
                {Title:"Expense Phase 3","Url":"expnse-detail-phase-3.png","Type":"image"},
                {Title:"Expense Phase 4","Url":"expnse-detail-phase-4.png","Type":"image"},
                {Title:"Monthly Fund","Url":"fund-statement-by-month.png","Type":"image"},
                {Title:"All Phases Expense Summary","Url":"summary-all-phases-expense.png","Type":"image"}
            ],
        },
        {
            "FolderName":"Expense 2021",
            "Year":2021,
            "Details":"Details of funds collected and expnsed in year 2021",
            "BaseUrl":"documents/welfare-clinic/expense/2021",
            "Links":[
                {Title:"Monthly expense","Url":"expense-statement-by-month.png","Type":"image"},
                {Title:"Monthly Fund","Url":"fund-statement-by-month.png","Type":"image"}
               
            ],
        },
    ]
   };

   const HelpingDeservingPeople = {
    "ProjectName":"Helping Deserving People",
    "Description" : "Purpose of this initiative is to help student, who cannot afford education expenses but want to continue their education. We have seperate fund for this program. ",
    "GalleryTitle":"Dewan Welfare Clinic Image Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
       ],
    "Documents":[
        {
            "FolderName":"Expense",
            "Year":2021,
            "Details":"Details of funds collected and expnsed in year 2021",
            "BaseUrl":"documents/deserving-students/expense/2021",
            "Links":[
                {Title:"Monthly expense","Url":"month-wise-expense-2021.png","Type":"image"},
                {Title:"Monthly Fund","Url":"month-wise-fund-2021.png","Type":"image"}
            ],
        },
        {
            "FolderName":"Students",
            "Year":2021,
            "Details":"Students selected to be part of this program in 2021",
            "BaseUrl":"documents/deserving-students/students/2021",
            "Links":[
                {Title:"List of students awarded" ,"Url":"students-2021.png","Type":"image"}
              
            ],
        }
    ]
   };

   const ProjectsDetails = {
   "DWC" : DewanWelfareClinic,
   "HDS" : HelpingDeservingPeople,
   "ERP" : EidRamzanPackages,
   "BTSP":TreePlantation,
   "ILP" :IslamicLibrary,
   "SCP" :StreetConstruction,
   "AP"  :Ambulance,
   "TUQ"  :TehfeezUlQuran,

   YearsOfPictureGalleries : [2019,2021,2022,2023],
   ProjectCodes : ["DWC","HDS","ERP","BTSP","ILP","SCP","TUQ"]
};

   
export default ProjectsDetails;
