import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import { useSearchParams } from "react-router-dom";
import ProjectsDetails from "../../Repositories/projects-repository"


function ProjectFolderWiseDocuments() {
   let [searchParams, setSearchParams] = useSearchParams();
  //searchParams.get("year");
  let projectId = new URLSearchParams(searchParams).get('projectid');
  //searchParams.get("year");
  let folder = new URLSearchParams(searchParams).get('folder');
  return (
    <>
    <TopBanner PageName={ProjectsDetails[projectId].GalleryTitle}></TopBanner>
    <div className="main-sec">
      <div className="plantaion-gallery text-center">
        <div className="container">
         <div className="row">
         {ProjectsDetails[projectId].Documents.map(name => (  
            
            name.FolderName == folder && <>
               <h1>{name.FolderName}</h1>
              {  name.Links.map(currLink => (
                <>
                {currLink.Type === "image" &&
               <div className="col">
                  <a href={name.BaseUrl +"/"+  currLink.Url} data-lightbox="photos" data-gallery="gallery">
                  <img src={name.BaseUrl +"/"+ currLink.Url} alt="Plant img"/> 
                  
                  <h4>{currLink.Title}</h4>
                  </a>
               </div>
} {currLink.Type === "docx" &&
               <div className="col">
                  <a href={name.BaseUrl +"/"+  currLink.Url} >
                  <img src={"images/word-document-icon.png"} alt="Plant img"/> 
                  
                  <h4>{currLink.Title}</h4>
                  </a>
               </div>
}
               </>
            
              ))}
            </>
         ))}
           
           
         
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default ProjectFolderWiseDocuments;
