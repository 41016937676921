import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import { useSearchParams } from "react-router-dom";
import ProjectsDetails from "../../Repositories/projects-repository"
import { Link } from "react-router-dom";

function ProjectDocuments() {
  let [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("projectid");
  let projectId = new URLSearchParams(searchParams).get('projectid');

  return (
    <>
    <TopBanner PageName={ProjectsDetails[projectId].GalleryTitle}></TopBanner>
    <div className="main-sec">
      <div className="plantaion-gallery text-center">
        <div className="container">
         <div className="row">
         {
         ProjectsDetails[projectId].Documents.map(name => (  
            <>
               <h2>{name.FolderName}</h2>
               <p>{name.Details}</p>
             
              <Link to={"/project/documents/folder/?projectid="+projectId+"&folder="+name.FolderName}>
                  Discover
              </Link>
              
            </>
         ))
         }
           
           
         
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default ProjectDocuments;
