import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import TopBanner from "../../Component/Inner-banner/banner";

function Donors() {
  const myArray = ["Doctor Muhammad Saleem sb","Muhammad Arif Butt Sb","Muhammad Zaheer Sb","Mian Muhammad Abbas Sb","Mian Muhammad Aslam Sb","Mian Muhammad Adnan Sb","Mian Muhammad Javed Qurashi Sb","Muhammad Ajmal Lodhi Sb","Haji Muhammad Adrase Sb","Haji Muhammad Sharif Sb","Muhammad Qasier Javed","Mlik Sagier Ahmad","Muhammad Larasib","Khuram aftab","Muhammad Saghir Dvr","Muhammad Ghouse Bilal","Mian Zaheer Udeen","Mian Muhammad Zahid","Mian Maser Muhammad Younis","Master Qasir Abbas","Muhammad Jwad Intzar","Ch Muhammad Irfan","Mian Umar Afzal","Raza ul haq","Master Zahoor ul hasan","Waheed ul hasan","Mian Hasan Aftam","Muhammad Shahid Butt","Master Shahzda Alam","Muhammad Nazir ","Muhammad Amir Nazir","Mian Sarfraz Ahamd","Khurram Mehmood","Yasir Arafat","Muhammad Tahir"];  
  return (
    <>
      <TopBanner PageName="Donors"></TopBanner>
      <div className="main-sec extra-space">
        <div className="services-box-wrap">
          <div className="container">
            <div className="row">
              <h1>Donors</h1>
              {myArray.map(name => (  
                <>
                <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faMale} />
                  <h2>{name}</h2>
                  <p>Regular</p>
                </div>
              </div>
              {/* <div className="col">
                <div className="service-box-inner">
                  <FontAwesomeIcon icon={faMale} />
                  <h2>Khurram</h2>
                  <p>Annual</p>
                </div>
              </div> */}
              </>
              ))}
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Donors;
