import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import { useSearchParams } from "react-router-dom";
import ProjectsDetails from "../../Repositories/projects-repository"


function PictureGalleryYear() {
   let [searchParams, setSearchParams] = useSearchParams();
  
 searchParams.get("year");
  let year = new URLSearchParams(searchParams).get('year');
  let yearWiseGalleries=[];
  Object.keys(ProjectsDetails).forEach(function(key) {
   if( ProjectsDetails[key].ImagesGallery !== undefined){
      
      console.log(year)
   var currProjectGalleries = ProjectsDetails[key].ImagesGallery.filter(currGallery => currGallery.Year == year);
   if(currProjectGalleries.length > 0){
   console.log(currProjectGalleries.length)
   yearWiseGalleries = yearWiseGalleries.concat(currProjectGalleries);
   console.table(yearWiseGalleries);
   }
   }
   
});
  return (
    <>
    <TopBanner PageName="Year Wise Gallery"></TopBanner>
    <div className="main-sec">
      <div className="plantaion-gallery text-center">
        <div className="container">
         <div className="row">
         {yearWiseGalleries.map(name => (  
            
            <>
               <h1>{name.FolderName}</h1>
              {  name.Images.map(currImage => (
                <>
               <div className="col">
                  <a href={name.BaseUrl +"/"+  currImage.Path} data-lightbox="photos" data-gallery="gallery">
                  <img src={name.BaseUrl +"/"+ currImage.Path} alt="Plant img"/>
                  <h4>{currImage.Title}</h4>
                  </a>
               </div>
               </>
            
              ))}
            </>
         ))}
           
           
           {/* <div className="col">
              <a href={PlantationImg2} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg2} alt="Plant img"/>
              <h4>Planting</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg3} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg3} alt="Plant img"/>
              <h4>Prayer 2</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg4} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg4} alt="Plant img"/>
              <h4>Prayer</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg5} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg5} alt="Plant img"/>
              <h4>Safety</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg6} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg6} alt="Plant img"/>
              <h4>Transport</h4>
              </a>
           </div>
           <div className="col">
              <a href={PlantationImg7} data-lightbox="photos" data-gallery="gallery">
              <img src={PlantationImg7} alt="Plant img"/>
              <h4>Planting 2</h4>
              </a>
           </div> */
           }
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default PictureGalleryYear;
