import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcaseMedical,
  faLightbulb
} from "@fortawesome/free-solid-svg-icons";
import TopBanner from "../../Component/Inner-banner/banner";

function FutureProject() {
  return (
    <>
    <TopBanner PageName="Future Project"></TopBanner>
    <div className="main-sec">
       <div className="project-top-wrap">
        <div className="container">
          <div className="row">
            <h2>Deewan Welfare Society Projects</h2>
            <p>Purposes of Establishment of Dewaan Welfare Society:</p>
            <ul>
              <li>
                To provide Monetary Help available for Widowed and Unassisted women
              </li>
              <li>
                Help economically weak families.
              </li>
              <li>
                Monetary help for poor and impecunious.
              </li>
              <li>
                Monetary help on occasion of marriages of orphan and deserving girls.
              </li>
              <li>
                Monetary help in educational expense of orphan and deserving students.
              </li>
              <li>
                Helping students acquiring education on college and university level.
              </li>
              <li>
                Providing ambulance at Basic health Unit for the patients.
              </li>
              <li>
                Help in arrangement of burial service on death of a poor person.
              </li>
              <li>
                Establishment of Islamic Library.
              </li>
              <li>
                Arrangement of facility of cleanliness of village streets and surroundings.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="services-box-wrap">
        <div className="container">
          <div className="row">
            <h1>Future Projects</h1>
            <div className="col">
              <div className="service-box-inner">
                <FontAwesomeIcon icon={faLightbulb} />
                <h2>Street Lights</h2>
              </div>
            </div>
            <div className="col">
              <div className="service-box-inner">
                <FontAwesomeIcon icon={faBriefcaseMedical} />
                <h2>SYT Medical Dispensary launching after ramadan</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default FutureProject;
