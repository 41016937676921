import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Routes} from "react-router-dom";
import './global.css';
import reportWebVitals from './reportWebVitals';
import {
  Header,
  Footer,
  MainNavbar,
} from "./Component/index";
import {
  Home,
  Contact,
  Organization,
  Donors,
  Donate,
  CompletedProject,
  CurrentProject,
  FutureProject,
  CurrentServices,
  DonationChannels,
  Overview,
  OverviewDetail2014,
  CompletedServices,
  FutureServices,
  Pictures,
  FebImages,
  ProjectYearWiseGallery,
  ProjectDetailed,
  OverviewDetail2021,
  ProjectGallery,
  ProjectDocuments,
  ProjectFolderWiseDocuments
} from "./Pages/index";
import PictureGalleryYear from './Pages/Galleries/picture-gallery-year';

ReactDOM.render(
  <Router>
    <Header />
    <MainNavbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/organization/" element={<Organization />} />
      <Route path="/donors/" element={<Donors />} />
      <Route path="/donate/" element={<Donate />} />
      <Route path="/completed-project/" element={<CompletedProject />} />
      <Route path="/current-project/" element={<CurrentProject />} />
      <Route path="/future-project/" element={<FutureProject />} />
      <Route path="/current-services/" element={<CurrentServices />} />
      <Route path="/donation-channels/" element={<DonationChannels />} />
      <Route path="/overview/" element={<Overview />} />
      <Route path="/overview-detail/2014/" element={<OverviewDetail2014 />} />
      <Route path="/overview-detail/2021/" element={<OverviewDetail2021 />} />
      <Route path="/completed-services/" element={<CompletedServices />} />
      <Route path="/future-services/" element={<FutureServices />} />
      <Route path="/pictures/" element={<Pictures />} />
      <Route path="/feb-images-2022/" element={<FebImages />} />
      <Route path="/project-year-wise-gallery/" element={<ProjectYearWiseGallery />} />
      <Route path="/project-gallery/" element={<ProjectGallery />} />
      <Route path="/picture-gallery-year/" element={<PictureGalleryYear />} />
      <Route path="/project-detailed/" element={<ProjectDetailed />} />
      <Route path="/project-documents/" element={<ProjectDocuments />} />
      <Route path="/project/documents/folder/" element={<ProjectFolderWiseDocuments />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
