import React from "react";




   const EidRamzanPackages = {
    "ProjectName":"Eid Ramzan Packages",
    "Description" : "Purpose of this initiative is to help poor and needy families. And to involve them in events of happiness.",
    "GalleryTitle":"Eid Ramzan Packages Gallery",
    "GalleryLink":"",
    "DocumentsLink":"",
    "ImagesGallery":[
        {
            "FolderName":"2022",
            "Year":2022,
            "Details":"In 2022 welfare clinic was build and augrated, this gallery has image of development and Inaugration",
            "BaseUrl":"images/eid-ramzan-packages/gallery/general",
            "Images":[
                {Title:"Eid Ramzan Package 1",Path: "eid-ramzan-pkgs-1.png"},
                {Title:"Eid Ramzan Package 2",Path: "eid-ramzan-pkgs-2.png"}
               
            ]
        }
       ],
    "Documents":[
        {
            "FolderName":"General",
            "Year":2022,
            "Details":"In 2022 welfare clinic was build and augrated, this gallery has image of development and Inaugration",
            "BaseUrl":"documents/eid-ramzan-packages",
            "Links":[
                {Title:"Project Details","Url":"project_details.docx","Type":"docx"},
               
            ],
        },
       
    ]
   };

   
   
export default EidRamzanPackages;
