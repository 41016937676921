import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";

function CompletedServices() {
  return (
    <>
    <TopBanner PageName="Completed Services"></TopBanner>
    <div className="main-sec">
      <div className="overview-detail-main-wrap">
        <div className="container">
          <h1>Coming Soon!</h1>
        </div>
      </div>
    </div>
    </>
  );
}
export default CompletedServices;
