import React from "react";
import { Container, Nav, NavDropdown } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import "../NavBar/navbar.css";

function MainNavbar() {
  return (
    <Navbar expand="md">
  <Container>
    <Navbar.Brand href="/#/">Menu</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <NavDropdown title="About" id="basic-nav-dropdown">
          {/* <NavDropdown.Item href="/#/news/">News</NavDropdown.Item> */}
          <NavDropdown.Item href="/#/contact-us/">Contact</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Services"  id="basic-nav-dropdown">
          <NavDropdown.Item href="/#/current-services/" >Current Services</NavDropdown.Item>
          <NavDropdown.Item href="/#/completed-services/">Completed Services</NavDropdown.Item>
          <NavDropdown.Item href="/#/future-services/">Future Services</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Projects" id="basic-nav-dropdown">
          <NavDropdown.Item href="/#/current-project/">Current Projects</NavDropdown.Item>
          <NavDropdown.Item href="/#/completed-project/">Completed Projects</NavDropdown.Item>
          <NavDropdown.Item href="/#/future-project/">Future Projects</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Donations" id="basic-nav-dropdown">
          <NavDropdown.Item href="/#/overview/">Overview</NavDropdown.Item>
          <NavDropdown.Item href="/#/donate/">How to donate</NavDropdown.Item>
          <NavDropdown.Item href="/#/donation-channels/">Donation Channels</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Gallery" id="basic-nav-dropdown">
          <NavDropdown.Item href="/#/pictures/">Pictures</NavDropdown.Item>
          <NavDropdown.Item href="/#/videos/">Videos</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Members" id="basic-nav-dropdown">
          <NavDropdown.Item href="/#/organization/">Organizations</NavDropdown.Item>
          <NavDropdown.Item href="/#/donors/">Donors</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
  );
}
export default MainNavbar;
