import React from "react";
import TopBanner from "../../Component/Inner-banner/banner";
import PlantImg from "../../images/2022-img.jpg"
import ProjectsDetails from "../../Repositories/projects-repository"
import { Link } from "react-router-dom";

function Pictures() {
 
  return (
    <>
    <TopBanner PageName="Pictures"></TopBanner>
    <div className="main-sec">
      <div className="pictures-sec text-center">
        <div className="container">
         <div className="row">{
         ProjectsDetails.YearsOfPictureGalleries.map(currYear => (  
            <>
            <div className="col">

              <img src={PlantImg} alt="Plant img"/>
              <h2>{currYear}</h2>
              <p></p>
              <Link to={"/picture-gallery-year/?year="+currYear}>
                  Discover
              </Link>
           </div>
               
             
             
              
            </>
         ))
         }
         </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default Pictures;
